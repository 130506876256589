//disable-eslint

import { createStore } from 'vuex'

import travelIcons from "./travelIcons"

const axios = require("axios")

const tagApi = axios.create({
    baseURL: "https://data.mobilites-m.fr/api/"
})

const tagApiOTP = axios.create({
    baseURL: "https://data.mobilites-m.fr/otp"
})

import { Network } from '@capacitor/network';

const store = createStore({

    state () {
        return {
            networkStatue: {
                connected: true
            },
            typeGrid: "large",
            lignes: [],
            lignesSEM: [],
            routes: [],
            notUse_Routes: [],
            test: null,
            lignesVoulues: [
                "TRAM",
                "CHRONO",
                "PROXIMO"
            ],

            lignesPossibles: [
                "TRAM",
                "CHRONO",
                "PROXIMO"
            ],

            travel : {},
            horaires: [],

            icons: [
                ...travelIcons
            ]
        }
    },


    getters: {

        getNetworkStatue: state => state.networkStatue,

        getLignes: (state) => {

            return state.lignes.filter((payload) => {

                return (state.routes.includes(payload.properties.NUMERO) && (!state.notUse_Routes.includes(payload.properties.NUMERO)))
            })
        },

        getLignesNoFilter: state => {

            return state.lignes.filter((payload) => {

                return (state.routes.includes(payload.properties.NUMERO))
            })
        },

        getLignesSEM: state => state.lignesSEM,

        getRoutes: state => state.routes,

        getNotUse_Routes: state => state.notUse_Routes,

        getTravel: state => state.travel,

        getHoraires: state => state.horaires,

        getIcons: state => state.icons,

        getGridType: state => state.typeGrid,

        getTest: state => state.test
    },


    mutations: {

        setNetworkStatue: (state, payload) => { state.networkStatue = payload },

        setLignes: (state, payload) => { state.lignes = payload },

        setLignesSEM: (state, payload) => { state.lignesSEM = payload },

        setRoutes: (state, payload) => { state.routes = payload },

        setReseaux: (state, payload) => { state.lignesVoulues = payload },

        setTravel: (state, payload) => { state.travel = payload },

        setHoraires: (state, payload) => {state.horaires = payload},

        setNotUse_Routes: (state, payload) => { state.notUse_Routes = payload },

        setGridType: (state) => {state.typeGrid =  (state.typeGrid === "large" ? "small" : "large")  },

        setTest: (state, payload) => { state.test = payload }
    },


    actions : {

        getLignesAPI: async ({ state, commit, dispatch }) => {

            try {

                // https://data.mobilites-m.fr/api/lines/json?types=ligne&reseaux=SEM
                const response = await tagApi.get("/lines/json", {
                    params: {
                        types: "ligne",
                        reseaux: "SEM"
                    }
                })

                var lignesSEM = []

                response.data.features.forEach((item) => {

                    lignesSEM.push(item.properties.NUMERO)
                })

                commit("setLignesSEM", lignesSEM)
                commit("setLignes" ,response.data.features)


            } catch {

                commit("networkStatue", false)
            }
        },

        getRoutesAPI: async ({ state, commit }) => {

            try {

                // https://data.mobilites-m.fr/api/routers/default/index/routes
                const response = await tagApi.get("/routers/default/index/routes", {
                    params: {
                        reseaux: state.lignesVoulues.join(",")
                    }
                })

                var routes = []

                response.data.forEach((payload) => {


                    if (state.lignesVoulues.includes(payload.type) && payload.type) {
                        routes.push(payload.shortName)
                    }
                })

                commit("setRoutes", routes)

            } catch {

                commit("setRoutes", state.offRoutes)
            }
        },

        getTravelsAPI: async ({ state, commit }, fromPlace, toPlace) => {

            try {

                // https://data.mobilites-m.fr/api/routers/default/plan
                const response = await tagApi.get("/routers/default/plan", {
                    params: {
                        routerId: "default",
                        locale: "fr_FR",
                        mode: "WALK,TRANSIT",
                        optimize: "QUICK",
                        showIntermediateStops: true,
                        numItineraries: "3",
                        maxWalkDistance: "1000",
                        arriveBy: false,
                        minTransferTime: "60",
                        transferPenalty: "60",
                        walkBoardCost: "300",

                        fromPlace: fromPlace,
                        toPlace : toPlace,
                        }
                })

                commit("setTravel", response.data)
            }

            catch {

                console.log("noTrue")
            }
        },

        getHorairesAPI: async ({ state, commit }, poteau, lignesSpecifiques) => {

            try {

                // https://data.mobilites-m.fr/api/routers/default/index/stops/XXXXXXX/stoptimes'
                const response = await tagApi.get(`/routers/default/index/clusters/${poteau}/stoptimes`, {
                    headers: {
                        origin: 'metafora'
                    },
                    params: {
                        route: lignesSpecifiques
                    }
                })

                commit("setHoraires", response.data);
            }

            catch {

                console.log("noTrue")
            }
        }

    }
})

export default store
