const travelIcons = [
    "Zepplin",
    "Map",
    "Travel Distance",
    "Cruise",
    "Segway",
    "Gateway",
    "Road",
    "Trailer Truck",
    "Fire Truck",
    "School Bus",
    "Driver",
    "Ticket Machine",
    "GPS",
    "Pickup",
    "Traffic Light",
    "Parking Car ",
    "Sport Coupe",
    "Ticket Bus",
    "Ambulance",
    "Parking Meter",
    "Stop Sign",
    "Way Finding",
    "Turnstile",
    "Bullet Train",
    "Campervan",
    "Helicopter",
    "Bus Station",
    "Jeep",
    "London Bus",
    "Subway Train",
    "Coupe",
    "Trem",
    "Electric Scooter",
    "Mail Truck",
    "Police",
    "Hatchback",
    "Golf Car",
    "Route",
    "tow truck",
    "Bike",
    "Plane",
    "Waiting Room",
    "Traffic Jam",
    "Scooter",
    "Hot Air Balloon",
    "Box Truck",
    "Locomotive",
    "Van",
    "Wagon",
    "Cable Car",
    "Taxi",
    "Sedan",
    "SUV",
    "Motorcycle ",
    "Traffic Cone",
    "Bus",
    "Tuk Tuk",
    "Sailboat",
    "Yacth",
    "Crossover",
]

export default travelIcons
