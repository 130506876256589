<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar class="tabBar" slot="bottom">
        <ion-tab-button tab="tab1" href="/tabs/tab1">
          <ion-icon class="tab1" :icon="route" />
          <ion-label>
            Itinéraires
          </ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" class="tab2" href="/tabs/tab2">
          <ion-icon class="tab2" :src="map" size="large" />

          <ion-label>
            Carte
          </ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3" href="/tabs/tab3">
          <ion-icon class="tab3" :icon="arrets" />
          <ion-label>
            Arrets
          </ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<style lang="scss" scoped>

ion-icon, ion-label {

  transition-duration: 0.6s;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
}

ion-icon {

  filter: grayscale(1);
}


.tab-selected {

  .tab1, .tab3 {

    filter: grayscale(0);
  }

  .tab2 {

    filter: grayscale(0);
  }


  ion-label {

    font-size: 0;

    opacity: 0;

    transition-delay: 0s;
    transition-duration: 0.5s;
    transition-timing-function: linear;
  }
}

</style>

<script setup>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';

const map = ("assets/Travel-Icons/Color/SVG/Map.svg")
const route = ("assets/Travel-Icons/Color/SVG/Bus Station.svg")
const arrets = ("assets/Travel-Icons/Color/SVG/Waiting Room.svg")
</script>
