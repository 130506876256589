<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';

import { Network } from '@capacitor/network';

export default {

  name: "mainApp",

  components: {

    IonApp,
    IonRouterOutlet
  },

  async created() {

    this.$store.commit("setNetworkStatue", await Network.getStatus())

    Network.addListener('networkStatusChange', status => {

      console.log("test")
      this.$store.commit("setNetworkStatue", status)
    });
  }
}
</script>
