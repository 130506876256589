import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsPage from '../pages/tabs/TabsPage.vue'

const routes = [
  {
    path: '/',
    redirect: '/tabs/tab1'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/tab1'
      },
      {
        path: 'tab1',
        component: () => import('@/pages/tabs/Tab1Page.vue')
      },
      {
        path: 'tab2',
        component: () => import('@/pages/tabs/Tab2Page.vue')
      },
      {
        path: 'tab3',
        component: () => import('@/pages/tabs/Tab3Page.vue')
      }
    ]
  },
  {
    path: "/stop/:idStop",
    component: () => import ('@/pages/stopItem.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
